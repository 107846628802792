@import "src/toon-balloon-app-theme.scss";

html,
body {
    height: 100%;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(42, 43, 42, 0.87);
        border-radius: 6px;
        box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);
    }

    &::-webkit-scrollbar-track {
        background: linear-gradient(90deg, #fff, #fff 1px, #fff 0, #fff);
    }

}

body {
    margin: 0;
    font-family: "ProximaNova", Roboto, "Helvetica Neue", sans-serif;
}

mat-form-field {
    width: 100%;
}

.time-main {
    .mat-form-field-subscript-wrapper {
        width: calc(100% + 74px) !important;
    }
}

.container {
    position: relative;
    min-height: calc(100vh - 34px);
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
}

.container-authenticated {
    position: relative;
    min-height: calc(100vh - 171px);
    padding-top: 80px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 25px;
    margin-bottom: 0px;

    &.bottom-no-padding {
        padding-bottom: 0px;
    }
}

.container-authenticated-ios {
    min-height: calc(100vh - 164px);
    padding-top: 100px;
}

.mat-drawer-container-fullscreen {
    max-height: 100vh !important;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    background-color: rgba(55, 56, 55, 0.4);
}

.loader {
    z-index: 1002;
}

.float-right {
    float: right;
}

.center-align {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fixed-title {
    position: fixed;

}

.text-center {
    text-align: center;
}

.hidden {
    display: none !important;
}

.mat-elevation-z6 {
    box-shadow: 0px 3px 5px -1px rgba(255, 255, 255, 0.2), 0px 6px 10px 0px rgba(255, 255, 255, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}

mat-sidenav.full {
    z-index: -10 !important;
}

mat-sidenav-content.full {
    overflow: hidden !important;
    margin-left: 0px !important;
    height: 100vh;
}

.center-form {
    position: relative;
    top: 50vh;
    transform: translateY(-50%);
}

.clear {
    clear: both;
}

.inline-header {
    display: inline-block !important;
    margin-top: 9px !important;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.inline-header-name {
    display: inline-block !important;
    margin-top: 9px !important;
}

.right-group {
    display: inline;
    margin-top: 6px !important;
    margin-left: 4px !important;
    z-index: 1;
}

.mat-grid-tile {
    overflow: visible !important;

    .mat-grid-tile-content {
        position: relative !important;
        display: block !important;
    }
}

.mat-icon {
    height: auto !important;
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
}

.mat-horizontal-content-container {
    overflow: hidden;
    padding: 0 16px 16px 16px !important;
}

.event-card-header {

    .mat-card-header-text {
        margin: 0 !important;
    }
}

.search {

    .mat-form-field-infix {
        width: 300px !important;
    }

}

.datefilter {

    .mat-form-field-infix {
        width: 100px !important;
    }

}

.mat-tab-body-content {
    overflow: hidden !important;
}

.mat-tab-label {
    min-width: 50px !important;
    padding: 0 24px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100% !important;
    line-height: 20pt !important;
}

.no-wrapper {
    .mat-form-field-wrapper {
        padding-bottom: 4px !important;
    }
}

// start make slider label always visible

.mat-slider-thumb-label {
    background-color: var(--primary-color) !important;
    width: 40px !important;
    height: 40px !important;
    right: -20px !important;
    top: -50px !important;
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
    transform: scale(0.5) !important;
}

.mat-slider-thumb-label-text {
    font-size: 12pt !important;
    opacity: 1 !important;
}

// end make slider label always visible

.icon-button {
    .mat-button-wrapper {
        mat-icon {
            vertical-align: bottom !important;
        }
    }

    &.full {
        width: 100%;
    }
}

.file-input-button {
    margin-bottom: 10px;
}

.fixed-save-button {
    position: fixed !important;
    bottom: 80px;
    right: 20px;
}

.fab-save-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8pt;

    mat-icon {
        vertical-align: inherit !important;
    }
}

.ngx-ic-overlay {
    outline: var(--cropper-overlay-color, trasparent) solid 100% !important;
}

// styling to get the button in the bottom navbar the right height
.bottom-navbar {

    button {

        height: 100%;

        span {
            line-height: 20px !important;

            .mat-badge-content {
                border-radius: 10px !important;
                width: auto !important;
                line-height: 16px !important;
                padding: 0px 3px !important;
            }
        }
    }

}

span {
    .mat-badge-content {
        border-radius: 10px !important;
        width: auto !important;
        line-height: 16px !important;
        padding: 0px 3px !important;
    }
}

.completeProfilePromptStepper {
    background-color: transparent !important;

    .mat-horizontal-content-container {
        display: none !important;
    }

    .mat-step-icon-state-number {
        color: #373837 !important;
    }

    .mat-step-icon-state-edit,
    .mat-step-icon-selected {
        background-color: #373837 !important;
        color: white !important;
    }

    .mat-step-header {
        cursor: default !important;

        &:hover {
            background: transparent !important;
        }

        &:focus {
            background: transparent !important;
        }
    }

}

.complete-action-prompt {
    max-width: 100vw !important;
    margin: 65px 1px 24px 24px !important;
}

.error-snackbar {
    color: #000 !important;
    background: var(--warn-color) !important;
}

.success-snackbar {
    color: #000 !important;
    background: var(--accent-color) !important;
}

.info-snackbar {
    color: #000 !important;
    background: var(--primary-color) !important;
}

.desktop-device {
    display: none !important;
}

.mobile-device {
    display: block !important;
}

.icon-black {
    color: #000;
}

.icon-white {
    color: #fff;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-dialog {
    width: 50%;
}

.hidden {
    display: none;
}

.show {
    display: block !important;
}

@media only screen and (min-width: 1080px) {
    .container-authenticated {
        // padding-top: 16px;
        margin-bottom: 0px;

        &.bottom-no-padding {
            padding-bottom: 0px;
        }
    }

    .desktop-device {
        display: block !important;
    }

    .mobile-device {
        display: none !important;
    }

}

@media only screen and (max-width: 1080px) {
    .video-dialog {
        width: 75%;
    }
}

@media only screen and (max-width: 768px) {

    .container-authenticated {
        margin-bottom: 58px;
    }
}

@media only screen and (max-width: 612px) {
    .mat-tab-label {
        padding: 0 4px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .video-dialog {
        width: 100%;
    }
}

@media only screen and (max-width: 599px) {
    .complete-action-prompt {
        margin: 57px auto 24px auto !important;
    }

    .container-authenticated-ios {
        min-height: calc(100vh - 164px);
        padding-top: 100px;
    }
}

// iOS mobile devices
@media only screen and (pointer: coarse) and (hover: none) {
    .container-authenticated {
        margin-bottom: 58px;
    }
}

// Android mobile devices
@media only screen and (pointer: coarse) and (hover: hover) {
    .container-authenticated {
        margin-bottom: 58px;
    }
}

@supports (-webkit-touch-callout: none) {

    /* CSS specific to iOS devices */
    select,
    textarea,
    input {
        font-size: 16px !important;
    }
}

// video stream

.media-controls {
    display: none;
}

.media-box {
    h2 {
        display: none !important;
    }
}

.local-video {
    position: absolute;
    top: 0px;
    width: 25%;
    max-width: 250px;
    z-index: 10;
    border-radius: 5px;
    margin: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    &.right {
        right: 0px;
    }

    &.left {
        left: 0px;
    }

    &.full {
        margin: 0px !important;
        position: absolute !important;
        width: 100% !important;
        // height: calc(100vh - 230px) !important;
        top: 50% !important;
        left: 50% !important;
        border-radius: 5px !important;
        transform: translate(-50%, -50%) !important;
        // object-fit: cover !important;
        max-width: 100% !important;
        border: none !important;
    }

}

.remote-video {
    position: absolute;
    // width: 100%;
    height: calc(100vh - 230px);
    left: 50%;
    border-radius: 5px;
    transform: translateX(-50%);
    // object-fit: cover;
    max-width: 100%;

    &.hidden {
        display: none;
    }
}

.fullscreen {
    position: absolute !important;
    top: 0px !important;
    left: 50% !important;
    max-width: 100vw !important;
    height: 100vh !important;
    z-index: 9 !important;
    transform: translateX(-50%) !important;
    border-radius: 0px !important;
    // object-fit: none !important;
}

@media only screen and (min-width: 1080px) {

    .local-video {

        &.full {
            height: calc(100vh - 170px) !important;
        }

    }

    .remote-video {
        height: calc(100vh - 170px);
    }
}

//vidio stream end

// barcode scanner start

.barcode-container {
    display: none;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.square {
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
}

.square:after {
    content: '';
    top: 0;
    display: block;
    padding-bottom: 100%;
}

.square>div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.surround-cover {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
}

.barcode-scanner--area--container {
    width: 80%;
    max-width: min(500px, 80vh);
    margin: auto;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.barcode-scanner--area--outer {
    display: flex;
    border-radius: 1em;
}

.barcode-scanner--area--inner {
    width: 100%;
    margin: 1rem;
    border: 2px solid #fff;
    box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 0.5),
        inset 0px 0px 2px 1px rgb(0 0 0 / 0.5);
    border-radius: 1rem;
}

.close-scanner-button {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    background-color: var(--primary-color);
    box-shadow: 0 3px 1px -2px #ccc3, 0 2px 2px #cccccc24, 0 1px 5px #cccccc1f;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)
}

@keyframes shake {
    0% {
        transform: translate(0, 0) rotate(0deg) scale(1);
    }

    20% {
        transform: translate(5px, 5px) rotate(-1deg) scale(1.05);
    }

    40% {
        transform: translate(5px, 5px) rotate(-2deg) scale(1.07);
    }

    60% {
        transform: translate(2px, 2px) rotate(0deg) scale(1.04);
    }

    80% {
        transform: translate(-1px, -1px) rotate(-2deg) scale(1.05);
    }

    100% {
        transform: translate(0, 0) rotate(0deg) scale(1);
    }
}

// barcode scanner end